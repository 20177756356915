@import '@aurora/shared-client/styles/_variables.pcss';

.lia-filter {
  &-container {
    padding-top: 20px;
  }

  &-items,
  &-expanded-items {
    display: flex;
    gap: 10px 20px;

    @media (--lia-breakpoint-down-sm) {
      padding: 0 10px 0 10px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--lia-bs-border-color);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  &-expanded-items {
    flex-wrap: wrap;
  }

  &-heading {
    font-size: var(--lia-bs-font-size-base);
    margin: auto;
  }

  &-expanded-items {
    @media (--lia-breakpoint-down-sm) {
      flex-direction: column;
      padding: 0;
    }
  }
}

.lia-widget-overflow-set .lia-filter-items {
  @media (--lia-breakpoint-down-sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.lia-is-hidden {
  display: none;
}

.lia-more {
  margin-left: auto;
  min-width: max-content;
}

.lia-items {
  display: flex;
  overflow-x: hidden;
  gap: 10px 20px;

  &:has([data-menu-open='true']) {
    overflow-x: unset;
  }
}
